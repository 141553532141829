import { toast } from 'react-toastify';
/* eslint-disable import/prefer-default-export */

/**
 * Formats given bytes as human readable string.
 *
 * @param {int} bytes Number of bytes.
 * @param {int} round Number of decimal places to display.
 * @param {func} t I18n's translation function.
 */
export const humanFileSize = ({ bytes, t, round = 1 }) => {
  // if (bytes < 1024) return `${bytes} ${t('units.B')}`;
  if (!bytes) return `0 ${t('units.B')}`;

  const units = ['B', 'KB', 'MB', 'GB'];
  const index = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = (bytes / (1024 ** index)).toFixed(round);
  const unit = t(`translation:units.${units[index]}`);

  return `${size} ${unit}`;
};

export const toastify = (status, message, delay = 225, options = {}) => {
  toast.dismiss();
  toast[status](message, { delay, ...options });
};

export const extractFileName = (url) => {
  if (url.startsWith('http') || url.startsWith('https')) {
    const startIndex = url.lastIndexOf('/') + 1;
    const endIndex = url.indexOf('?');
    return url.substring(startIndex, endIndex !== -1 ? endIndex : url.length);
  }
  return url;
};

export const getSelectControlObject = (value, label = value, key) => (
  { label, value, ...(key !== undefined && { key }) }
);
