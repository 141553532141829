import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-relay';
import Modal from 'react-bootstrap/Modal';

import AlertContext from 'src/contexts/AlertContext';
import Button from 'src/components/Shared/Button/Button';
import FlashMsg from 'src/components/Shared/FlashMsg/FlashMsg';
import DeleteJobRequestMutation from '../JobRequestMutation/DeleteJobRequestMutation';

const DeleteJobRequestModal = ({ currentJobRequest }) => {
  const { t } = useTranslation(['jobRequests', 'translation']);
  const { showAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [deleteJobRequest] = useMutation(DeleteJobRequestMutation);

  const [formErrors, setformErrors] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const [show, setShow] = useState(false);

  const displayFormErrors = serverErrors?.length || formErrors?.length;

  const handleClose = () => {
    setShow(false);
    setformErrors([]);
  };

  const handleShow = () => setShow(true);

  const deleteAlertMessage = () => t('deleteConfiramationMessage');

  const handleSuccessDeletedJobRequest = () => {
    navigate('/job-requests');
    showAlert(t('deleteSuccess'));
  };

  const onSubmit = (_, { setSubmitting }) => {
    setSubmitting(true);
    const input = { id: currentJobRequest.dbId };

    deleteJobRequest({
      variables: { input },
      onCompleted: ({ deleteJobRequest: deleteResponse }) => {
        setSubmitting(false);
        const { jobRequest, errors } = deleteResponse;

        if (jobRequest !== null) {
          handleSuccessDeletedJobRequest(jobRequest.dbId);
        } else if (errors.length > 0) displayFormErrors(errors);
      },
      onError: (responseErrors) => {
        setSubmitting(false);
        setServerErrors(responseErrors);
      },
    });
  };

  return (
    <>
      <Button
        className="mb-0"
        handleBtnClick={handleShow}
        text={t('deleteJobRequest')}
        type="button"
        variant="danger"
        dataTestId="TestId-deleteJobRequest"
      />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('areYouSure')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteAlertMessage()}
          <FlashMsg
            errorMessage={serverErrors}
            errors={formErrors}
            variant="danger"
            title={(
              <>
                <FontAwesomeIcon icon={faExclamationCircle} />
                {t('formErrorsTitle')}
              </>
            )}
            isDisplayed={displayFormErrors}
          />
          <Formik
            initialValues={{}}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="default"
                    type="button"
                    text={t('translation:cancel')}
                    handleBtnClick={handleClose}
                    className="ms-0 me-2 mb-0 mt-4"
                  />
                  <Button
                    variant="danger"
                    type="submit"
                    text={isSubmitting ? t('translation:wait') : t('translation:submit')}
                    className="mx-0 mb-0 mt-4"
                    dataTestId="DeleteJobRequest"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

DeleteJobRequestModal.propTypes = {
  currentJobRequest: PropTypes.shape({
    dbId: PropTypes.number,
    uploadStatus: PropTypes.string,
  }).isRequired,
};

export default DeleteJobRequestModal;
