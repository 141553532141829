/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type DicomViewTypeEnum = "hl7" | "manual" | "orthanc" | "%future added value";
export type PatientAgeUnitEnum = "days" | "months" | "years" | "%future added value";
export type PatientGenderEnum = "female" | "male" | "%future added value";
export type StatusEnum = "assigned" | "draft" | "not_valid" | "pending" | "published" | "report_uploaded" | "verified" | "%future added value";
export type UploadStatusEnum = "cancelled" | "completed" | "failed" | "initiated" | "not_started" | "%future added value";
export type UrgencyEnum = "normal" | "urgent" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JobRequestFragment_jobRequest$ref: FragmentReference;
declare export opaque type JobRequestFragment_jobRequest$fragmentType: JobRequestFragment_jobRequest$ref;
export type JobRequestFragment_jobRequest = {|
  +dbId: number,
  +patientAge: ?number,
  +patientAgeUnit: ?PatientAgeUnitEnum,
  +patientFullname: ?string,
  +patientGender: ?PatientGenderEnum,
  +examType: ?{|
    +id: string,
    +dbId: number,
    +name: ?string,
    +modality: {|
      +id: string,
      +dbId: number,
      +name: ?string,
    |},
  |},
  +urgency: ?UrgencyEnum,
  +contrast: ?string,
  +status: ?StatusEnum,
  +id: string,
  +patientClinicalData: ?string,
  +patientAdditionalInfo: ?string,
  +referringDoctor: ?string,
  +contract: ?{|
    +id: string,
    +dbId: number,
    +user: {|
      +id: string,
      +dbId: number,
      +name: string,
    |},
  |},
  +attachments: ?$ReadOnlyArray<string>,
  +dicom: ?string,
  +dicomType: ?string,
  +previousStudy: ?any,
  +modality: ?string,
  +uploadStatus: ?UploadStatusEnum,
  +dicomViewType: ?DicomViewTypeEnum,
  +studyDetails: ?{|
    +studyDesc: ?string,
    +studyDate: ?string,
    +studyTime: ?string,
  |},
  +radiologist: ?{|
    +dbId: number,
    +id: string,
  |},
  +report: ?any,
  +reportPdf: ?any,
  +draftReport: ?any,
  +createdAt: ?any,
  +remainingTat: ?string,
  +remainingTatInMins: ?string,
  +datasetIdAssignedToMedicalFacility: number,
  +isMedicalFacilityAssignedToBasicDataset: boolean,
  +$refType: JobRequestFragment_jobRequest$ref,
|};
export type JobRequestFragment_jobRequest$data = JobRequestFragment_jobRequest;
export type JobRequestFragment_jobRequest$key = {
  +$data?: JobRequestFragment_jobRequest$data,
  +$fragmentRefs: JobRequestFragment_jobRequest$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v0/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobRequestFragment_jobRequest",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientAge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientAgeUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientFullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientGender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examType",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Modality",
          "kind": "LinkedField",
          "name": "modality",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urgency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contrast",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientClinicalData",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientAdditionalInfo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referringDoctor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contract",
      "kind": "LinkedField",
      "name": "contract",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previousStudy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modality",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dicomViewType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyDetails",
      "kind": "LinkedField",
      "name": "studyDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyDesc",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "studyTime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "radiologist",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "report",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reportPdf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingTat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingTatInMins",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datasetIdAssignedToMedicalFacility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMedicalFacilityAssignedToBasicDataset",
      "storageKey": null
    }
  ],
  "type": "JobRequest",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '70560c352469ace4fd868059dd5f3e16';

module.exports = node;
