import graphql from 'babel-plugin-relay/macro';

const CancelSyncDicomFileUploadMutation = graphql`
  mutation CancelSyncDicomFileUploadMutation($input: CancelSyncDicomFileUploadInput!) {
    cancelSyncDicomFileUpload(input:$input) {
      jobRequest {
        ...JobRequestFragment_jobRequest
      }
      errors
    }
  }
`;

export default CancelSyncDicomFileUploadMutation;
