import PropTypes from 'prop-types';
import './TitleStyle.scss';

const SecondaryTitle = (props) => {
  const {
    title,
    className,
  } = props;
  return (
    <div className={`app-title ${className}`}>
      <div className="d-flex">
        <h5>{title}</h5>
        <div className="flex-grow-1 horizontal-border" />
      </div>
    </div>
  );
};
SecondaryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
SecondaryTitle.defaultProps = {
  className: '',
};

export default SecondaryTitle;
