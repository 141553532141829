import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faHome } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';

import HelmetWrapper from 'src/hocs/HelmetWrapper/HelmetWrapper';
import AppBreadcrumb from '../Shared/BreadCrumb/AppBreadcrumb';
import Title from '../Shared/Title/Title';
import FlashMsg from '../Shared/FlashMsg/FlashMsg';
import JobRequestDetailsSection from './JobRequestDetailsSection';
import CompleteJobRequestForm from './CompleteJobRequestForm';

import '../../assets/scss/SharedStyle/FormStyle.scss';
import './JobRequestStyle.scss';

const CompleteJobRequest = ({ jobRequest, abilities }) => {
  const { t } = useTranslation(['jobRequests', 'errors', 'translation', 'uploader']);

  const [formErrors, setformErrors] = useState([]);
  const [displayFormErrors, setdisplayFormErrors] = useState(false);

  const breadcrumbItems = [
    {
      key: 1,
      title:
  <span>
    <FontAwesomeIcon icon={faHome} />
    {`${t('translation:homeTitle')}`}
  </span>,
      link: '/',
      className: '',
    },
    {
      key: 2,
      title: t('completeJobRequest'),
      link: `/job-request/${jobRequest.dbId}/complete`,
      className: 'active',
    },
  ];

  return (
    <div className="complete-job-request">
      <div className="form-style">
        <HelmetWrapper>
          <title>{t('completeJobRequest')}</title>
        </HelmetWrapper>
        <AppBreadcrumb
          breadcrumbitems={breadcrumbItems}
          className="page-head"
        />
        <Title
          title={t('completeJobRequest')}
          className="page-head"
        />
        <Container>
          <div className="main-card make-it-top">
            <FlashMsg
              errors={formErrors}
              variant="danger"
              title={(
                <p>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                  {t('formErrorsTitle')}
                </p>
              )}
              isDisplayed={displayFormErrors}
            />
            <JobRequestDetailsSection jobRequest={jobRequest} />
            <CompleteJobRequestForm
              setformErrors={setformErrors}
              setdisplayFormErrors={setdisplayFormErrors}
              currentJobRequest={jobRequest}
              abilities={abilities}
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

CompleteJobRequest.propTypes = {
  jobRequest: PropTypes.instanceOf(Object).isRequired,
  abilities: PropTypes.instanceOf(Object).isRequired,
};

export default CompleteJobRequest;
