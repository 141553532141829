import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './AttachmentStyle.scss';

const Attachments = ({
  attahmentItems,
  number,
  name,
  className,
  icon,
}) => {
  const { t } = useTranslation('translation');

  return (
    <div className={`attachment ${className}`}>
      <span className="attachment-item">
        <span className="overlay">
          <a rel="noreferrer" href={attahmentItems} target="_blank">
            <FontAwesomeIcon icon={faEye} />
          </a>
          <a
            rel="noreferrer"
            href={attahmentItems}
            download={attahmentItems}
            target="_blank"
          >
            <FontAwesomeIcon icon={faDownload} />
          </a>
        </span>

        <img src={icon} alt={t('translation:downloadWithOutCounter')} />
      </span>

      <span className="attachment-name" title={name}>
        {name || (
          <>
            {t('translation:attachment')}
            -
            {number}
          </>
        )}
      </span>
    </div>
  );
};

Attachments.propTypes = {
  number: PropTypes.number,
  attahmentItems: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

Attachments.defaultProps = {
  attahmentItems: '',
  number: 0,
  name: '',
  className: '',
};
export default Attachments;
