import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toastify } from 'src/utils/helpers';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-relay';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'src/components/Shared/Button/Button';
import FlashMsg from 'src/components/Shared/FlashMsg/FlashMsg';
import { UPLOAD_STATUSES } from 'src/utils/constants';
import CancelSyncDicomFileUploadMutation from '../JobRequestMutation/CancelSyncDicomFileUploadMutation';

const CancelSyncFileUploadModal = ({ currentJobRequest }) => {
  const { t } = useTranslation(['showJobRequests', 'translation']);
  const [commit] = useMutation(CancelSyncDicomFileUploadMutation);

  const { dbId, uploadStatus } = currentJobRequest;

  const [formErrors, setformErrors] = useState([]);
  const [serverErrors, setServerErrors] = useState();
  const displayFormErrors = serverErrors?.length || formErrors?.length;
  const [show, setShow] = useState(false);

  const displayUnAssignSuccessMsg = () => {
    toastify('success', 'success');
  };

  const handleClose = () => {
    setShow(false);
    setformErrors([]);
  };
  const handleShow = () => setShow(true);

  const cancelAlertMessage = () => {
    if (uploadStatus === UPLOAD_STATUSES.completed) {
      return t('jobRequests:confirmRemoveSyncFile');
    }

    return t('jobRequests:confirmCancelSyncFile');
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    setformErrors([]);

    const input = { ...values, id: Number(dbId) };

    const handleCompletion = (data) => {
      setSubmitting(false);

      const { errors } = data.cancelSyncDicomFileUpload;

      if (errors.length === 0) {
        handleClose();
        displayUnAssignSuccessMsg();
      } else {
        setformErrors(errors);
      }
    };

    const handleError = (error) => {
      setSubmitting(false);
      setServerErrors(error.message);
    };

    commit({
      variables: { input },
      onCompleted: handleCompletion,
      onError: handleError,
    });
  };

  return (
    <>
      <Button
        className="close-toast m-0"
        handleBtnClick={handleShow}
        text={(
          <span>
            {t('jobRequests:cancelSyncronizationAndUpload')}
            <FontAwesomeIcon icon={faUpload} className="mx-2" />
          </span>
        )}
        type="button"
        variant="primary"
        dataTestId="UnAssignBtnId"
      />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('jobRequests:areYouSure')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {cancelAlertMessage()}
          <FlashMsg
            errorMessage={serverErrors}
            errors={formErrors}
            variant="danger"
            title={(
              <>
                <FontAwesomeIcon icon={faExclamationCircle} />
                {t('formErrorsTitle')}
              </>
            )}
            isDisplayed={displayFormErrors}
          />
          <Formik
            initialValues={{}}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="default"
                    type="button"
                    text={t('translation:cancel')}
                    handleBtnClick={handleClose}
                    className="ms-0 me-2 mb-0 mt-4"
                  />
                  <Button
                    variant="danger"
                    type="submit"
                    text={isSubmitting ? t('translation:wait') : t('translation:submit')}
                    className="mx-0 mb-0 mt-4"
                    dataTestId="UnAssignSubmitId"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

CancelSyncFileUploadModal.propTypes = {
  currentJobRequest: PropTypes.shape({
    dbId: PropTypes.number,
    uploadStatus: PropTypes.string,
  }).isRequired,
};

export default CancelSyncFileUploadModal;
