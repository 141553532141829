import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import FileSize from 'src/utils/FileSize';
import {
  Col, FormLabel, Row, Form,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faImages } from '@fortawesome/free-solid-svg-icons';
import eventBus from 'src/EventBus';
import { SUBMIT_TYPES } from 'src/utils/constants';

import Btn from '../Shared/Button/Button';
import SelectControl from '../Shared/SelectOptions/Select';
import UploadingFilesModal from '../Shared/UploadingFilesModal/UploadingFilesModal';
import AppAccordion from '../Shared/Accordion/AppAccordion';
import DragDropFileInput from '../Shared/Form/FileInput/DragDropFileInput';
import FormikInput from '../Shared/Form/FormikInput/FormikInput';
import FormikRadioButton from '../Shared/Form/FormikRadioButton/FormikRadioButton';
import FormikCheckboxes from '../Shared/Form/FormikCheckboxes/FormikCheckboxes';
import FormikErrorMessage from '../Shared/Form/FormikErrorMessage/FormikErrorMessage';
import ShowLoader from '../Shared/Loader/Loader';
import { getSelectControlObject } from '../../utils/helpers';
import ValidationSchema from './ValidationSchema';

const AbstractJobRequestForm = ({
  initialValues,
  examTypes,
  onSubmit,
  dicomContentSection,
  allowToDuplicateJobRequest,
  allowToSaveDraft,
  isLoadingProgress,
  allowToCreateJobRequest,
  DeleteJobRequestModal,
  allowToDeleteJobRequest,
}) => {
  const { t } = useTranslation(['jobRequests', 'errors', 'translation', 'uploader']);

  const navigate = useNavigate();
  const home = () => navigate('/');

  const getExamTypes = (modailitydbId) => new Promise((resolve) => {
    resolve(examTypes.filter((examType) => examType.modality.dbId === modailitydbId));
  });

  const [progress, setProgressValue] = useState(0);

  React.useEffect(() => {
    eventBus.on('uploadProgress', (progressEvent) => {
      setProgressValue(progressEvent.percentage);
    });
    return () => {
      eventBus.remove('uploadProgress');
    };
  }, []);

  const dicomItems = [
    {
      label: 'File',
      id: 'File',
      radioValue: 'File',
      key: 1,
    },
    {
      label: 'URL',
      id: 'Url',
      radioValue: 'Url',
      key: 2,
    },
  ];

  const duplicateRequestCheckboxItems = [
    {
      fieldText: t('fields.duplicateRequest'),
      fieldName: 'duplicateRequest',
      key: 1,
      fieldFor: 'duplicateRequest',
    },
  ];

  const previousStudyCheckboxItems = [
    {
      fieldText: t('fields.hasPreviousStudy'),
      fieldName: 'requirePreviousStudy',
      key: 1,
      fieldFor: 'requirePreviousStudy',
    },
  ];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (

          <Form onSubmit={handleSubmit}>
            {
              isSubmitting && <ShowLoader />
            }
            <Row>
              <AppAccordion
                accordionHeader={t('basicInfo')}
                eventKey="1"
                accordionContent={(
                  <>
                    <Row className="mb-2">
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <Form.Label
                            htmlFor="modalityId"
                            className="required"
                          >
                            {t('fields.modalityId')}
                          </Form.Label>
                          <SelectControl
                            selectId="modalityId"
                            handleChange={async (e) => {
                              const { value } = e;
                              const selectedExamTypes = await getExamTypes(value);
                              setFieldValue('modalityId', e);
                              setFieldValue('examTypeId', '');
                              setFieldValue('examTypes', selectedExamTypes);
                            }}
                            options={values.modalities.map(
                              ({ dbId, name }) => (getSelectControlObject(dbId, name)),
                            )}
                            name="modalityId"
                            value={values.modalityId}
                            onBlur={handleBlur}
                            className={touched.modalityId && errors.modalityId ? 'has-error' : null}
                            placeholder={t('modalityPlaceholder')}
                          />
                          <p className="error-message mt-1">
                            <FormikErrorMessage fieldName="modalityId" />
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <Form.Label
                            htmlFor="examTypeId"
                            className="required"
                          >
                            {t('fields.examTypeId')}
                          </Form.Label>
                          <SelectControl
                            selectId="examTypeId"
                            handleChange={(e) => {
                              setFieldValue('examTypeId', e);
                            }}
                            options={values.examTypes && values.examTypes.map(
                              ({ dbId, name, modality }) => (getSelectControlObject(dbId,
                                name, modality.dbId)),
                            )}
                            name="examTypeId"
                            value={values.examTypeId}
                            onBlur={handleBlur}
                            className={touched.examTypeId && errors.examTypeId ? 'has-error' : null}
                            isDisabled={values.examTypes.length === 0}
                            placeholder={t('examTypePlaceholder')}
                          />
                          <p className="error-message mt-1">
                            <FormikErrorMessage fieldName="examTypeId" />
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <div
                            key="inline-radio"
                            className={`mb-3 normal-radio ${touched.urgency && errors.urgency ? 'has-error' : null}`}
                          >
                            <FormLabel className="required d-md-inline-block">{t('fields.urgency')}</FormLabel>
                            <Form.Check
                              inline
                              label="Normal"
                              name="req"
                              type="radio"
                              className="mx-4"
                              id="normal"
                              checked={values.urgency === 'normal'}
                              onChange={() => setFieldValue('urgency', 'normal')}
                            />
                            <Form.Check
                              inline
                              label="Urgent"
                              name="req"
                              type="radio"
                              id="urgent"
                              className="mx-4"
                              checked={values.urgency === 'urgent'}
                              onChange={() => setFieldValue('urgency', 'urgent')}
                              data-testid="TestId-urgent"
                            />
                            <p className="error-message mt-1">
                              <FormikErrorMessage fieldName="urgency" />
                            </p>
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <div
                            key="inline-radio"
                            className="mb-3 normal-radio"
                          >
                            <FormLabel className="required d-md-inline-block">{t('fields.contrast')}</FormLabel>
                            <Form.Check
                              inline
                              name="contrast"
                              label={t('without')}
                              type="radio"
                              className="mx-4"
                              id="without"
                              data-testid="TestId-without"
                              checked={values.contrast === false}
                              onChange={() => setFieldValue('contrast', false)}
                            />
                            <Form.Check
                              inline
                              name="contrast"
                              label={t('with')}
                              type="radio"
                              id="with"
                              data-testid="TestId-with"
                              className="mx-4"
                              checked={values.contrast === true}
                              onChange={() => setFieldValue('contrast', true)}
                            />
                            <p className="error-message mt-1">
                              <FormikErrorMessage fieldName="contrast" />
                            </p>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              />
              <AppAccordion
                accordionHeader={t('patientInformation')}
                eventKey="2"
                accordionContent={(
                  <>
                    <Row className="mb-2">
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <Form.Label className="required">{t('fields.patientFullName')}</Form.Label>
                          <Form.Control
                            type="text"
                            name="patientFullname"
                            placeholder={t('fields.patientFullName')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patientFullname}
                            className={touched.patientFullname && errors.patientFullname ? 'has-error' : null}
                            data-testid="TestId-patientFullName"
                          />
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <p className="error-message mt-1">
                            <FormikErrorMessage fieldName="patientFullname" />
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <FormLabel className="required">{t('fields.patientGender')}</FormLabel>
                          <div
                            key="inline-radio"
                            className={`mb-3 styled-radio ${touched.patientGender && errors.patientGender ? 'has-error' : null}`}
                          >
                            <Form.Check
                              inline
                              label="Male"
                              name="patientGender"
                              type="radio"
                              id="gender-male"
                              className="radio-item w-50"
                              checked={values.patientGender === 'male'}
                              onChange={() => setFieldValue('patientGender', 'male')}
                              data-testid="TestId-patientMaleGender"

                            />
                            <Form.Check
                              inline
                              label="Female"
                              name="patientGender"
                              type="radio"
                              id="gender-female"
                              className="radio-item w-50"
                              checked={values.patientGender === 'female'}
                              onChange={() => setFieldValue('patientGender', 'female')}
                            />
                            <p className="error-message mt-1">
                              <FormikErrorMessage fieldName="patientGender" />
                            </p>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative age">
                          <Form.Label className="required">{t('fields.patientAge')}</Form.Label>
                          <Form.Control
                            type="number"
                            name="patientAge"
                            placeholder={t('fields.patientAge')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patientAge}
                            className={`patient-age d-inline-block ${touched.patientAge && errors.patientAge ? 'has-error' : null}`}
                            data-testid="TestId-patientAge"
                          />
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <SelectControl
                            handleChange={(e) => {
                              setFieldValue('patientAgeUnit', e);
                            }}
                            options={['Days', 'Months', 'Years'].map(
                              (unit) => ({ value: unit.toLowerCase(), label: unit }),
                            )}
                            placeholder="Years"
                            name="patientAgeUnit"
                            value={values.patientAgeUnit}
                            onBlur={handleBlur}
                            className="age-unit d-inline-block"
                          />
                          <p className="error-message mt-1">
                            <FormikErrorMessage fieldName="patientAge" />
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <Form.Label className="required">{t('fields.patientClinicalData')}</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="patientClinicalData"
                            placeholder={t('fields.patientClinicalData')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.patientClinicalData}
                            className={touched.patientClinicalData && errors.patientClinicalData ? 'has-error' : null}
                            data-testid="TestId-patientClinicalData"
                          />
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <p className="error-message mt-1">
                            <FormikErrorMessage fieldName="patientClinicalData" />
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={{ span: 6 }}>
                        <Form.Group className="mb-4 position-relative">
                          <Form.Label>
                            {t('fields.patientAdditionalInfo')}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            name="patientAdditionalInfo"
                            value={values.patientAdditionalInfo}
                            placeholder={t('fields.patientAdditionalInfo')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              />
              <AppAccordion
                accordionHeader={t('referringInformation')}
                eventKey="3"
                accordionContent={(
                  <Row className="mb-4 position-relative">
                    <Col md={{ span: 6 }}>
                      <Form.Group className="mb-4">
                        <Form.Label>{t('fields.referringDoctor')}</Form.Label>
                        <Form.Control
                          type="text"
                          name="referringDoctor"
                          placeholder={t('fields.referringDoctor')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.referringDoctor}
                          className={touched.referringDoctor && errors.referringDoctor ? 'has-error' : null}
                        />
                        <FontAwesomeIcon icon={faExclamationCircle} />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              />
              <AppAccordion
                accordionHeader={t('attachmentTitle')}
                eventKey="4"
                accordionContent={(
                  <DragDropFileInput
                    id={t('fields.attachment')}
                    label={t('fields.attachment')}
                    maxFiles={10}
                    accept={['.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png']}
                    prefix="attachmentsAttributes"
                    fieldName="attachments"
                    acceptedAttachments={values.attachmentsAttributes[1].attachments}
                    fieldPlaceholder={(
                      <span className="dropzone-placeholder">
                        <FontAwesomeIcon icon={faImages} />
                        {t('uploader:dragDrop')}
                        <span className="text-primary">{t('uploader:browse')}</span>
                        <span className="hint">{t('attachmentsHintGeneral')}</span>
                      </span>
                    )}
                    hint={(
                      <Trans i18nKey="uploader:maxNumber" count="10">
                        <span className="px-1" />
                      </Trans>
                    )}
                    setFieldValue={(value) => {
                      setFieldValue('attachmentsAttributes',
                        {
                          ...values.attachmentsAttributes, ...value,
                        });
                    }}
                    isMulti
                  />
                )}
              />
              <AppAccordion
                accordionHeader={t('dicomTitle')}
                eventKey="5"
                accordionContent={(
                  <>
                    {
                      dicomContentSection || (
                        <>
                          <Row>
                            <Col md={{ span: 6 }}>
                              <Form.Group className="mb-4 position-relative">
                                <FormLabel className="required d-md-inline-block">
                                  {t('dicomTitle')}
                                </FormLabel>
                                <FormikRadioButton
                                  titleClassName="required mr-4"
                                  fieldName="dicomRadio"
                                  className="title-label default-radio w-50 two-radio d-inline-block mx-4" // Added a space between 'd-inline-block' and 'mx-4'
                                  radioBtnItems={dicomItems}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            {values.dicomRadio === 'Url' ? (
                              <Col md={{ span: 6 }}>
                                <FormikInput
                                  id="dicom"
                                  fieldType="text"
                                  fieldTestId="dicomUrl"
                                  fieldPlaceholder={t('fields.url')}
                                  fieldName="dicom"
                                />
                              </Col>
                            ) : (
                              <Col md={{ span: 12 }}>
                                <DragDropFileInput
                                  id="dicom"
                                  accept={['.rar', '.zip']}
                                  prefix="attachmentsAttributes"
                                  fieldName="dicom"
                                  fieldTestId="dicomFile"
                                  acceptedAttachments={
                                    values.attachmentsAttributes[0]?.dicom
                                      ? [values.attachmentsAttributes[0].dicom]
                                      : []
                                  } // Added optional chaining to handle potential undefined error
                                  maxSize={new FileSize(2, 'GB')}
                                  minSize={new FileSize(5, 'KB')}
                                  fieldPlaceholder={(
                                    <span className="dropzone-placeholder">
                                      <FontAwesomeIcon icon={faImages} />
                                      {t('uploader:dragDrop')}
                                      <span className="text-primary">{t('uploader:browse')}</span>
                                      <span className="hint">{t('dicomHint')}</span>
                                    </span>
                                  )}
                                  setFieldValue={(value) => {
                                    setFieldValue('attachmentsAttributes', {
                                      ...values.attachmentsAttributes,
                                      ...value,
                                    });
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                        </>
                      )
                    }

                    <FormikCheckboxes
                      className="mb-3 mb-md-0 mt-4 dark-16-base-checkbox"
                      fieldName="requirePreviousStudy"
                      checkboxItems={previousStudyCheckboxItems}
                    />
                    <small className="hint">{t('previousStudyHint')}</small>
                  </>
                )}
              />
            </Row>
            <div className="justify-content-between d-lg-flex align-items-center footer-border-top pt-4">
              <div className="w-md-50">
                {
                  allowToDuplicateJobRequest && (
                    <>
                      <FormikCheckboxes
                        className="mb-3 mb-md-0 ms-4 dark-16-base-checkbox"
                        fieldName="duplicateRequest"
                        checkboxItems={duplicateRequestCheckboxItems}
                      />
                      <small className="hint">{t('duplicateRequestHint')}</small>
                    </>
                  )
                }
              </div>
              <div>
                <Btn
                  variant="outline-secondary"
                  handleBtnClick={home}
                  text={t('translation:cancel')}
                  className="mb-0"
                />
                {
                  allowToSaveDraft && (
                    <Btn
                      variant="secondary"
                      type="submit"
                      handleBtnClick={() => {
                        setFieldValue('submitType', SUBMIT_TYPES.SAVE_DRAFT);
                      }}
                      disabledBtn={isSubmitting}
                      text={t('saveAsDraft')}
                      className="mb-0"
                      dataTestId="TestId-saveAsDraft"
                    />
                  )
                }
                {
                  allowToDeleteJobRequest && DeleteJobRequestModal
                }
                {
                  (allowToCreateJobRequest(values))
                  && (
                    <Btn
                      className="mb-0"
                      variant="primary"
                      type="submit"
                      handleBtnClick={() => {
                        setFieldValue('submitType', SUBMIT_TYPES.COMPLETE);
                      }}
                      disabledBtn={isSubmitting}
                      text={isSubmitting ? t('translation:wait') : t('createAction')}
                      dataTestId="TestId-submit"
                    />
                  )
                }
              </div>
              {
                isLoadingProgress ? <UploadingFilesModal withProgressBar percentage={progress} /> : ''
              }
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

AbstractJobRequestForm.propTypes = {
  initialValues: PropTypes.instanceOf(Object),
  examTypes: PropTypes.arrayOf(Object),
  onSubmit: PropTypes.func,
  dicomContentSection: PropTypes.node,
  allowToDuplicateJobRequest: PropTypes.bool,
  allowToSaveDraft: PropTypes.bool,
  isLoadingProgress: PropTypes.bool,
  allowToCreateJobRequest: PropTypes.func,
  DeleteJobRequestModal: PropTypes.instanceOf(Object),
  allowToDeleteJobRequest: PropTypes.bool,
};

AbstractJobRequestForm.defaultProps = {
  initialValues: {},
  examTypes: [],
  onSubmit: () => {},
  dicomContentSection: null,
  allowToDuplicateJobRequest: false,
  allowToSaveDraft: false,
  isLoadingProgress: false,
  allowToCreateJobRequest: () => {},
  DeleteJobRequestModal: {},
  allowToDeleteJobRequest: false,
};

export default AbstractJobRequestForm;
