import { useEffect, useState } from 'react';
import { useError } from '../components/Errors/ErrorPageHandler';
import { errorsSet, SERVER_ERROR } from '../components/Errors/ErrorCodes';

export default (serverErrors, options) => {
  const setError = useError();
  const [errorStatusCode, setErrorStatusCode] = useState(null);
  const { skip } = options || {};

  useEffect(() => {
    if (skip) return;

    if (!errorStatusCode) return;

    setError(errorStatusCode);
  }, [errorStatusCode, setErrorStatusCode, setError]);

  if (skip) return;
  if (errorStatusCode || !serverErrors || serverErrors.length === 0) return;

  let isKnownError = false;
  serverErrors?.every(({ statusCode }) => {
    if (errorsSet.has(statusCode)) {
      setErrorStatusCode(statusCode);
      isKnownError = true;
      return false; // break the loop
    }

    return true; // next iteration
  });

  // The server returned an unkown error,
  // i.e. the error's status_code doesn't belong to the know error codes.
  if (!isKnownError) setErrorStatusCode(SERVER_ERROR);
};
