import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import PropTypes from 'prop-types';
import spinner from '../../../assets/images/spinner.svg';
import AppProgressBar from '../AppProgressBar/AppProgressBar';
import '../../../assets/scss/SharedStyle/AppModalStyle.scss';
import './UploadingFilesModalStyle.scss';

const UploadingFilesModal = ({
  withProgressBar,
  percentage,
}) => {
  const { t } = useTranslation('translation');
  return (
    <>
      <Modal
        show
        centered
        backdrop="static"
        keyboard={false}
        className="white-bg text-center job-request-modal"
      >
        <div className="modal-content">
          <Modal.Header className="d-block">
            <Image src={spinner} className="mb-3" />
            <Modal.Title>
              { t(percentage === 100 ? 'processingFiles' : 'uploadingFiles') }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { t(percentage === 100 ? 'processingFilesHint' : 'uploadingFilesHint') }
            { withProgressBar && <AppProgressBar percentage={percentage} className="mt-4 primary" /> }
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

UploadingFilesModal.propTypes = {
  withProgressBar: PropTypes.bool,
  percentage: PropTypes.number,
};

UploadingFilesModal.defaultProps ={
  withProgressBar: true,
  percentage: 0,
};

export default UploadingFilesModal;
