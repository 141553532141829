export const AUTH_TOKEN_KEY = 'authToken';
export const REFRESH_TOKEN_KEY = 'expert-i-refreshToken';
export const IS_REMEMBER_ME_KEY = 'expert-i-rememberme';
export const ALERT_DURATION = 5000;

/**
 * Job requests upload statuses
 */
export const UPLOAD_STATUSES = {
  not_started: 'not_started',
  initiated: 'initiated',
  failed: 'failed',
  completed: 'completed',
  cancelled: 'cancelled',
};

/**
 * Job request statuses
 */
export const JOB_REQUEST_STATUSES = {
  pending: 'pending',
  assigned: 'assigned',
  published: 'published',
  not_valid: 'not valid',
  verified: 'verified',
  report_uploaded: 'report upload',
  draft: 'draft',
};

/**
 * DICOM view types
 */
export const DICOMViewTypes = {
  orthanc: 'orthanc',
  manual: 'manual',
  hl7: 'hl7',
};

/**
 * DICOM types
 */
export const DICOMTypes = {
  FILE: 'file',
  URL: 'url',
};

export const SUBMIT_TYPES = {
  SAVE_DRAFT: 'saveDraft',
  COMPLETE: 'complete',
};
