/* eslint-disable relay/unused-fields */
import graphql from 'babel-plugin-relay/macro';

const DraftJobRequestQuery = graphql`
  query DraftJobRequestQuery($id: Int!) {
    jobRequest(id: $id) {
      ...JobRequestFragment_jobRequest
    }
    abilities {
      canDeleteJobRequest(id: $id)
    }
    serverErrors
  }
`;
export default DraftJobRequestQuery;
