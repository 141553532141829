import { useTranslation } from 'react-i18next';
import lock from '../../assets/images/lock.png';

const NoAccess = () => {
  const { t } = useTranslation('showJobRequest');
  return (
    <div className="text-center no-access">
      <img src={lock} alt={t('showJobRequest:cantAccess')} />
      <p>{t('showJobRequest:cantAccess')}</p>
      <p className="radOnly">{t('showJobRequest:radOnly')}</p>
    </div>
  );
};

export default NoAccess;
