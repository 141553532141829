import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks';

import useServerErrorsHandler from 'src/hooks/useServerErrorsHandler';
import { DICOMTypes, SUBMIT_TYPES, UPLOAD_STATUSES } from 'src/utils/constants';
import { getSelectControlObject } from 'src/utils/helpers';
import AlertContext from 'src/contexts/AlertContext';

import useJobRequest from 'src/hooks/useJobRequest';

import AbstractJobRequestForm from './AbstractJobRequestForm';
import SaveJobRequestMutation from './JobRequestMutation/SaveJobRequestMutation';

import ModalitiesExamTypesQuery from './JobRequestQuery/JobRequestQuery';
import DeleteJobRequestModal from './JobRequestQuery/DeleteJobRequestModal';

import '../../assets/scss/SharedStyle/FormStyle.scss';
import './JobRequestStyle.scss';

const CompleteJobRequestForm = ({
  setformErrors,
  setdisplayFormErrors,
  currentJobRequest,
  abilities,
}) => {
  const { t } = useTranslation(['jobRequests', 'errors', 'translation', 'uploader']);
  const navigate = useNavigate();

  const [saveAsDraft, isSaving] = useMutation(SaveJobRequestMutation);
  const {
    isCancelled,
    dicom,
    dicomType,
    dicomContentSection,
  } = useJobRequest(currentJobRequest);

  const {
    modalities,
    examTypes,
    serverErrors,
  } = useLazyLoadQuery(ModalitiesExamTypesQuery);

  if (serverErrors) {
    useServerErrorsHandler(serverErrors);
    return null;
  }

  const { showAlert } = useContext(AlertContext);

  const dicomAttachment = dicom && dicomType === DICOMTypes.FILE ? { dicom } : {};
  const requestAttachments = currentJobRequest?.attachments || [];
  const initialAttachmentsAttributes = [dicomAttachment, { attachments: requestAttachments }];

  const initialValues = {
    patientFullname: currentJobRequest?.patientFullname || '',
    patientAge: currentJobRequest?.patientAge || '',
    patientAgeUnit: currentJobRequest
      ? getSelectControlObject(currentJobRequest.patientAgeUnit)
      : currentJobRequest.patientAgeUnit('years', 'Years'),
    patientClinicalData: currentJobRequest?.patientClinicalData || '',
    referringDoctor: currentJobRequest?.referringDoctor || '',
    patientGender: currentJobRequest?.patientGender || 'male',
    modalityId: currentJobRequest?.examType?.modality
      ? getSelectControlObject(currentJobRequest.examType.modality.dbId,
        currentJobRequest.examType.modality.name) : '',
    examTypeId: currentJobRequest?.examType
      ? getSelectControlObject(currentJobRequest.examType.dbId, currentJobRequest.examType.name)
      : '',
    urgency: currentJobRequest?.urgency || 'normal',
    dicomRadio: dicomType === DICOMTypes.URL ? 'Url' : 'File',
    patientAdditionalInfo: currentJobRequest?.patientAdditionalInfo || '',
    dicom: currentJobRequest?.dicom && dicomType === DICOMTypes.URL ? currentJobRequest?.dicom : '',
    examTypes: currentJobRequest?.examType?.modality ? examTypes : [],
    modalities,
    attachmentsAttributes: initialAttachmentsAttributes,
    contrast: currentJobRequest.contrast === 'With' || false,
    duplicateRequest: false,
    requirePreviousStudy: false,
    submitType: SUBMIT_TYPES.SAVE_DRAFT,
  };

  const allowToCreateJobRequest = (values) => {
    const { uploadStatus } = currentJobRequest;
    const isUploadIncomplete = ![UPLOAD_STATUSES.completed, UPLOAD_STATUSES.cancelled]
      .includes(uploadStatus) && !dicom;

    if (isUploadIncomplete) return false;

    let isValid = values.patientFullname && values.patientAge && values.patientAgeUnit
      && values.patientClinicalData && values.modalityId && values.examTypeId;

    if (values.dicomRadio === 'Url') {
      isValid = isValid && values.dicom;
    } else {
      isValid = isValid && values.attachmentsAttributes.dicom;
    }

    return isValid;
  };

  const handleSuccessCreatedJobRequest = (JobRequestId, submitType, { resetForm }) => {
    const jobRequestURL = `/job-request/${JobRequestId}`;
    resetForm();
    navigate(jobRequestURL);
    showAlert(submitType === SUBMIT_TYPES.COMPLETE ? t('createSuccess') : t('saveDraftSuccess'));
  };

  const getRequestInput = (values) => {
    const isManualDicomUrl = values?.dicomRadio === 'Url' && isCancelled;
    const deletedAttachmentsList = values.attachmentsAttributes.attachmentsDeleted;
    const deletedDicomFile = values.attachmentsAttributes.dicomDeleted;
    return {
      jobRequestId: currentJobRequest?.dbId,
      contrast: values.contrast,
      examTypeId: values.examTypeId.value,
      patientAdditionalInfo: values.patientAdditionalInfo,
      patientAge: values.patientAge ? values.patientAge : null,
      patientAgeUnit: values.patientAgeUnit.value,
      patientClinicalData: values.patientClinicalData,
      patientFullname: values.patientFullname,
      patientGender: values.patientGender,
      referringDoctor: values.referringDoctor,
      urgency: values.urgency,
      isComplete: values.submitType === SUBMIT_TYPES.COMPLETE,
      requirePreviousStudy: values.requirePreviousStudy,
      ...(isManualDicomUrl && values.dicom ? { dicom: values.dicom } : {}),
      ...(isManualDicomUrl && !values.dicom ? { dicomDeleted: values.dicom } : {}),
      ...(deletedAttachmentsList ? { attachmentsDeleted: deletedAttachmentsList } : {}),
      ...(deletedDicomFile ? { dicomDeleted: deletedDicomFile } : {}),
    };
  };

  const getRequestUploadables = (values) => {
    const uploadables = {};

    Object.entries(values.attachmentsAttributes).forEach(([key, value]) => {
      if (key === 'dicom' && values.dicomRadio === 'File' && value instanceof File) {
        uploadables[key] = value;
      } else if (key === 'attachments') {
        uploadables[key] = value.filter((attachment) => attachment instanceof File);
      }
    });
    return Object.keys(uploadables).length ? uploadables : null;
  };

  const displayFormErrors = (errors) => {
    window.scroll(0, 0);
    setformErrors(errors);
    setdisplayFormErrors(true);
  };

  const onErrorCallBack = (responseErrors) => {
    const typeErrors = responseErrors.extensions?.problems;
    const formattedErrors = [];
    Object.values(typeErrors).forEach((fieldError) => {
      formattedErrors.push({
        field: fieldError.path[0],
        message: fieldError?.explanation,
      });
    });
    displayFormErrors(formattedErrors);
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    setformErrors([]);
    setdisplayFormErrors(false);

    const input = getRequestInput(values);
    const uploadables = getRequestUploadables(values);

    saveAsDraft({
      variables: { input },
      uploadables,
      onCompleted: ({ saveJobRequest }) => {
        setSubmitting(false);
        const { jobRequest, errors } = saveJobRequest;
        if (jobRequest !== null) {
          handleSuccessCreatedJobRequest(jobRequest.dbId, values.submitType, { resetForm });
        } else if (errors.length > 0) displayFormErrors(errors);
      },
      onError: (responseErrors) => {
        setSubmitting(false);
        onErrorCallBack(responseErrors);
      },
    });
  };

  return (
    <AbstractJobRequestForm
      initialValues={initialValues}
      examTypes={examTypes}
      onSubmit={onSubmit}
      dicomContentSection={dicomContentSection}
      allowToDuplicateJobRequest={false}
      allowToSaveDraft
      isLoadingProgress={isSaving}
      allowToCreateJobRequest={allowToCreateJobRequest}
      DeleteJobRequestModal={<DeleteJobRequestModal currentJobRequest={currentJobRequest} />}
      allowToDeleteJobRequest={abilities.canDeleteJobRequest}
    />
  );
};

CompleteJobRequestForm.propTypes = {
  currentJobRequest: PropTypes.instanceOf(Object),
  setformErrors: PropTypes.func,
  setdisplayFormErrors: PropTypes.func,
  abilities: PropTypes.instanceOf(Object).isRequired,
};

CompleteJobRequestForm.defaultProps = {
  currentJobRequest: null,
  setformErrors: () => { },
  setdisplayFormErrors: () => { },
};

export default CompleteJobRequestForm;
