import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import AppAccordion from '../Shared/Accordion/AppAccordion';
import DataView from '../Shared/DataView/DataView';
import { formatStudyDate, formatStudyTime } from '../../utils/studyDetails';

const JobRequestDetailsSection = ({ jobRequest }) => {
  const { t } = useTranslation(['jobRequests']);

  return (
    <>
      <AppAccordion
        accordionHeader="Job Request Details"
        eventKey="1"
        accordionContent={(
          <>
            <Row className="mb-2">
              <Col md={{ span: 6 }}>
                <DataView
                  label={t('fields.patientFullName')}
                  value={jobRequest?.patientFullname || 'N/A'}
                />
              </Col>
              <Col md={{ span: 6 }}>
                <DataView
                  label={t('fields.studyDetails')}
                  value={`${jobRequest.studyDetails?.studyDesc || 'N/A'}, ${formatStudyDate(jobRequest.studyDetails?.studyDate)}, ${formatStudyTime(jobRequest.studyDetails?.studyTime)}`}
                />
              </Col>
              <Col md={{ span: 6 }}>
                <DataView
                  label={t('fields.modality')}
                  value={jobRequest?.modality || 'N/A'}
                />
              </Col>
            </Row>
          </>
        )}
      />
    </>
  );
};

JobRequestDetailsSection.propTypes = {
  jobRequest: PropTypes.shape({
    patientFullname: PropTypes.string,
    modality: PropTypes.string,
    studyDetails: PropTypes.shape({
      studyDesc: PropTypes.string,
      studyDate: PropTypes.string,
      studyTime: PropTypes.string,
    }),
  }),
};

JobRequestDetailsSection.defaultProps = {
  jobRequest: null,
};

export default JobRequestDetailsSection;
