import { useContext } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import AuthContext from 'src/contexts/AuthContext';
import useServerErrorsHandler from 'src/hooks/useServerErrorsHandler';
import { JOB_REQUEST_STATUSES } from 'src/utils/constants';

import Error404 from '../Errors/Error404';
import CompleteJobRequest from './CompleteJobRequest';

import DraftJobRequestQuery from './JobRequestQuery/DraftJobRequestQuery';
import JobRequestFragment from '../ListJobRequest/ListJobRequestQueries/JobRequestFragment';

const CompleteJobRequestLoader = () => {
  const { id } = useParams();

  const { jobRequest: ref, abilities, serverErrors } = useLazyLoadQuery(DraftJobRequestQuery, {
    id: Number(id),
  });

  const jobRequest = useFragment(JobRequestFragment, ref);

  // TODO: Should be here using HOC for the following conditions
  const { isRadiologist } = useContext(AuthContext);

  if (isRadiologist()) {
    return <Error404 />;
  }

  if (jobRequest?.status !== JOB_REQUEST_STATUSES.draft) {
    return <Error404 />;
  }

  if (serverErrors) {
    useServerErrorsHandler(serverErrors);
    return null;
  }

  return <CompleteJobRequest jobRequest={jobRequest} abilities={abilities} />;
};

export default CompleteJobRequestLoader;
