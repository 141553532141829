/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JobRequestQueryVariables = {||};
export type JobRequestQueryResponse = {|
  +examTypes: $ReadOnlyArray<{|
    +id: string,
    +dbId: number,
    +name: ?string,
    +modality: {|
      +dbId: number
    |},
  |}>,
  +modalities: $ReadOnlyArray<{|
    +id: string,
    +dbId: number,
    +name: ?string,
  |}>,
  +abilities: ?{|
    +canCreateJobRequest: boolean
  |},
  +serverErrors: ?$ReadOnlyArray<any>,
|};
export type JobRequestQuery = {|
  variables: JobRequestQueryVariables,
  response: JobRequestQueryResponse,
|};
*/


/*
query JobRequestQuery {
  examTypes {
    id
    dbId
    name
    modality {
      dbId
      id
    }
  }
  modalities {
    id
    dbId
    name
  }
  abilities {
    canCreateJobRequest
  }
  serverErrors
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Modality",
  "kind": "LinkedField",
  "name": "modalities",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Abilities",
  "kind": "LinkedField",
  "name": "abilities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canCreateJobRequest",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverErrors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamType",
        "kind": "LinkedField",
        "name": "examTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Modality",
            "kind": "LinkedField",
            "name": "modality",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JobRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExamType",
        "kind": "LinkedField",
        "name": "examTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Modality",
            "kind": "LinkedField",
            "name": "modality",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "2aa131c2dc7fc6637bf45b6f3234165c",
    "id": null,
    "metadata": {},
    "name": "JobRequestQuery",
    "operationKind": "query",
    "text": "query JobRequestQuery {\n  examTypes {\n    id\n    dbId\n    name\n    modality {\n      dbId\n      id\n    }\n  }\n  modalities {\n    id\n    dbId\n    name\n  }\n  abilities {\n    canCreateJobRequest\n  }\n  serverErrors\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '539ad456272caf08d94623b6e7331fef';

module.exports = node;
