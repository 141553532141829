import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import MedicalFacilityRoute from './MedicalFacilityRoute';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import RadiologistRoute from './RadiologistRoute';
import UnKnownRoute from './UnKnownRoute';
import CompleteJobRequestLoader from '../components/JobRequest/CompleteJobRequestLoader';

const EditRadiologistProfile = lazy(() => import('../components/EditProfile/EditRadiologistProfile'));
const ShowJobRequest = lazy(() => import('../components/ShowJobRequest/ShowJobRequest'));
const ViewContract = lazy(() => import('../components/Contract/ViewContract'));
const ViewRadiologistProfile = lazy(() => import('../components/ViewRadiologistProfile/ViewRadiologistProfile'));
const JobRequest = lazy(() => import('../components/JobRequest/JobRequest'));
const ListJobRequestContainer = lazy(() => import('../components/ListJobRequest/ListJobRequestContainer'));
const Login = lazy(() => import('../components/Login/Login'));

const AppRoutes = () => (
  <Routes>
    <Route
      exact
      path="/create-job-request"
      element={(
        <ProtectedRoute>
          <MedicalFacilityRoute>
            <JobRequest />
          </MedicalFacilityRoute>
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/job-request/:id"
      element={(
        <ProtectedRoute>
          <ShowJobRequest />
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/job-request/:id/complete"
      element={(
        <ProtectedRoute>
          <CompleteJobRequestLoader />
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/contract"
      element={(
        <ProtectedRoute>
          <ViewContract />
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/profile/edit"
      element={(
        <ProtectedRoute>
          <RadiologistRoute>
            <EditRadiologistProfile />
          </RadiologistRoute>
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/profile"
      element={(
        <ProtectedRoute>
          <RadiologistRoute>
            <ViewRadiologistProfile />
          </RadiologistRoute>
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/job-requests"
      element={(
        <ProtectedRoute>
          <ListJobRequestContainer />
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/"
      element={(
        <ProtectedRoute>
          <ListJobRequestContainer />
        </ProtectedRoute>
      )}
    />
    <Route
      exact
      path="/login"
      element={(
        <PublicRoute>
          <Login />
        </PublicRoute>
      )}
    />
    <Route path="*" element={<UnKnownRoute />} />
  </Routes>
);

export default AppRoutes;
