import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './AppBreadcrumbStyle.scss';

const AppBreadcrumb = (props) => {
  const {
    breadcrumbitems,
    className,
  } = props;
  return (
    <div className={`app-breadcrumb ${className}`}>
      <Container>
        <Breadcrumb>
          {
            breadcrumbitems.map((item) => (
              <Breadcrumb.Item
                key={item.key}
                linkAs={Link}
                linkProps={{ to: item.link }}
                className={item.className}
                aria-label={item.title}
              >
                {item.title}
              </Breadcrumb.Item>
            ))
          }
        </Breadcrumb>
      </Container>
    </div>
  );
};
AppBreadcrumb.propTypes = {
  breadcrumbitems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      link: PropTypes.string.isRequired,
      className: PropTypes.string,
      key: PropTypes.number.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
};

AppBreadcrumb.defaultProps = {
  className: '',
};

export default AppBreadcrumb;
