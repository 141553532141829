import graphql from 'babel-plugin-relay/macro';

const JobRequestFragment = graphql`
  fragment JobRequestFragment_jobRequest on JobRequest{
    dbId
    patientAge
    patientAgeUnit
    patientFullname
    patientGender
    examType{
        id
        dbId
        name
        modality {
            id
            dbId
            name
        }
    }
    urgency
    contrast
    status
    id
    patientClinicalData
    patientAdditionalInfo
    referringDoctor
    contract {
        id
        dbId
        user{
            id
            dbId
            name
        }
    }
    attachments
    dicom
    dicomType
    previousStudy
    modality
    uploadStatus
    dicomViewType
    studyDetails {
      studyDesc
      studyDate
      studyTime
    }
    radiologist {
      dbId
      id
    }
    report
    reportPdf
    draftReport
    createdAt
    remainingTat
    remainingTatInMins
    datasetIdAssignedToMedicalFacility
    isMedicalFacilityAssignedToBasicDataset
  }
`;
export default JobRequestFragment;
