/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PatientAgeUnitEnum = "days" | "months" | "years" | "%future added value";
export type PatientGenderEnum = "female" | "male" | "%future added value";
export type UrgencyEnum = "normal" | "urgent" | "%future added value";
export type SaveJobRequestInput = {|
  attachments?: ?$ReadOnlyArray<any>,
  attachmentsDeleted?: ?$ReadOnlyArray<string>,
  clientMutationId?: ?string,
  contrast?: ?boolean,
  dicom?: ?any,
  dicomDeleted?: ?string,
  examTypeId: number,
  isComplete: boolean,
  jobRequestId: number,
  patientAdditionalInfo?: ?string,
  patientAge?: ?number,
  patientAgeUnit?: ?PatientAgeUnitEnum,
  patientClinicalData?: ?string,
  patientFullname?: ?string,
  patientGender?: ?PatientGenderEnum,
  referringDoctor?: ?string,
  requirePreviousStudy?: ?boolean,
  urgency?: ?UrgencyEnum,
|};
export type SaveJobRequestMutationVariables = {|
  input: SaveJobRequestInput
|};
export type SaveJobRequestMutationResponse = {|
  +saveJobRequest: ?{|
    +jobRequest: ?{|
      +dbId: number
    |},
    +errors: any,
  |}
|};
export type SaveJobRequestMutation = {|
  variables: SaveJobRequestMutationVariables,
  response: SaveJobRequestMutationResponse,
|};
*/


/*
mutation SaveJobRequestMutation(
  $input: SaveJobRequestInput!
) {
  saveJobRequest(input: $input) {
    jobRequest {
      dbId
      id
    }
    errors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SaveJobRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveJobRequestPayload",
        "kind": "LinkedField",
        "name": "saveJobRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobRequest",
            "kind": "LinkedField",
            "name": "jobRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SaveJobRequestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveJobRequestPayload",
        "kind": "LinkedField",
        "name": "saveJobRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobRequest",
            "kind": "LinkedField",
            "name": "jobRequest",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34d0a0186138b0f225c40f66824d7349",
    "id": null,
    "metadata": {},
    "name": "SaveJobRequestMutation",
    "operationKind": "mutation",
    "text": "mutation SaveJobRequestMutation(\n  $input: SaveJobRequestInput!\n) {\n  saveJobRequest(input: $input) {\n    jobRequest {\n      dbId\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'efdaf626c72c01c02fc889b034d39b77';

module.exports = node;
