/* eslint-disable max-len */

/**
 * Formats a study date string in the format 'YYYYMMDD' to 'YYYY/MM/DD'.
 *
 * @param {string} studyDate - The string representing a study date in 'YYYYMMDD' format.
 * @returns {string} - A formatted date string in 'YYYY/MM/DD' format. Returns 'N/A' if the input is invalid or null.
 */
export const formatStudyDate = (studyDate) => (studyDate
  ? `${studyDate.slice(0, 4)}/${studyDate.slice(4, 6)}/${studyDate.slice(6, 8)}`
  : 'N/A');

/**
 * Formats a study time string in the format 'HHMMSS' to 'HH:MM:SS'.
 *
 * @param {string} studyTime - The string representing a study time in 'HHMMSS' format.
 * @returns {string} - A formatted time string in 'HH:MM:SS' format. Returns 'N/A' if the input is invalid or null.
 */
export const formatStudyTime = (studyTime) => (studyTime
  ? `${studyTime.slice(0, 2)}:${studyTime.slice(2, 4)}:${studyTime.slice(4, 6)}`
  : 'N/A');
