import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Badge from '../../Shared/Badge/Badge';
import { UPLOAD_STATUSES } from '../../../utils/constants';
import CancelSyncFileUploadModal from './CancelSyncFileUploadModal';

const DicomFileSection = ({ jobRequest }) => {
  const { uploadStatus } = jobRequest;
  const { t } = useTranslation(['jobRequests']);

  return (
    <>
      <p>
        {t('fileStatus')}
        <Badge
          variant={uploadStatus}
          text={t(`uploadStatus.${UPLOAD_STATUSES[uploadStatus]}`)}
          className="status-badge"
        />
      </p>
      <div className="text-center">
        <p>
          {t('notAvailableFile')}
          {' '}
          {UPLOAD_STATUSES[uploadStatus]}
        </p>
        <CancelSyncFileUploadModal
          currentJobRequest={jobRequest}
        />
      </div>
    </>
  );
};

DicomFileSection.propTypes = {
  jobRequest: PropTypes.instanceOf(Object).isRequired,
};

export default DicomFileSection;
