import PropTypes from 'prop-types';
import AppBadge from 'react-bootstrap/Badge';
import './BadgeStyle.scss';

const Badge = (props) => {
  const {
    text,
    variant,
    className,
    pill,
  } = props;
  return (
    <AppBadge
      bg={variant}
      className={className}
      pill={pill}
    >
      {text}
    </AppBadge>
  );
};
Badge.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  pill: PropTypes.bool,
};
Badge.defaultProps = {
  text: '',
  className: '',
  pill: false,
};

export default Badge;
