import graphql from 'babel-plugin-relay/macro';

const DeleteJobRequestMutation = graphql`
  mutation DeleteJobRequestMutation($input: DeleteJobRequestInput!) {
    deleteJobRequest(input:$input) {
      jobRequest {
        dbId
      }
      errors
    }
  }
`;

export default DeleteJobRequestMutation;
