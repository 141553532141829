import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import zipIcon from '../../../assets/images/zipIcon.svg';
import SecondaryTitle from '../../Shared/Title/SecondaryTitle';
import Attachment from '../../Shared/Attachment/Attachment';
import { fileNameFromURL } from '../../../utils/strings';
import NoAccess from '../../ShowJobRequest/NoAccess';
import { DICOMTypes } from '../../../utils/constants';
import CancelSyncFileUploadModal from './CancelSyncFileUploadModal';

const CompletedDicomFileSection = ({ jobRequest, isHl7 }) => {
  const { t } = useTranslation(['showJobRequest', 'jobRequests']);
  const { dicomType, dicom } = jobRequest;

  return (
    <>
      <SecondaryTitle title={t('showJobRequest:labels.dicomFile')} className="mb-3" />
      {dicom !== null ? (
        <>
          {dicomType === DICOMTypes.FILE ? (
            <Attachment
              attahmentItems={dicom}
              name={fileNameFromURL(dicom)}
              className="text-center"
              icon={zipIcon}
            />
          ) : (
            <p className="dicomFile">
              <FontAwesomeIcon icon={faLink} />
              <a href={dicom} target="_blank" rel="noreferrer">{dicom}</a>
            </p>
          )}
          {
            !isHl7 ?? (
              <div className="text-center">
                <CancelSyncFileUploadModal
                  currentJobRequest={jobRequest}
                />
              </div>
            )
          }
        </>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

CompletedDicomFileSection.propTypes = {
  jobRequest: PropTypes.instanceOf(Object).isRequired,
  isHl7: PropTypes.bool,
};

CompletedDicomFileSection.defaultProps = {
  isHl7: false,
};

export default CompletedDicomFileSection;
