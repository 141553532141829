/* eslint-disable relay/unused-fields */
import graphql from 'babel-plugin-relay/macro';

const JobRequestQuery = graphql`
  query JobRequestQuery{
    examTypes{
      id
      dbId
      name
      modality{
        dbId
      }
    }
    modalities{
      id
      dbId
      name
    }
    abilities {
      canCreateJobRequest
    }
    serverErrors
  }
`;

export default JobRequestQuery;
