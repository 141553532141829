import { useMemo } from 'react';
import { DICOMViewTypes, UPLOAD_STATUSES } from 'src/utils/constants';
import DicomFileSection from 'src/components/JobRequest/DICOMFilesSections/DicomFileSection';
import CompletedDicomFileSection from 'src/components/JobRequest/DICOMFilesSections/CompletedDicomFileSection';

const useJobRequest = (currentJobRequest) => {
  const { dicom, dicomType, uploadStatus } = currentJobRequest || {};

  const isOrthanc = useMemo(() => currentJobRequest?.dicomViewType === DICOMViewTypes.orthanc,
    [currentJobRequest]);

  const isHl7 = useMemo(() => currentJobRequest?.dicomViewType === DICOMViewTypes.hl7,
    [currentJobRequest]);

  const isCancelled = useMemo(() => currentJobRequest?.uploadStatus === UPLOAD_STATUSES.cancelled,
    [currentJobRequest]);

  const DicomSectionComponentMapper = {
    [UPLOAD_STATUSES.initiated]: DicomFileSection,
    [UPLOAD_STATUSES.failed]: DicomFileSection,
    [UPLOAD_STATUSES.completed]: CompletedDicomFileSection,
  };

  const getDicomSectionComponent = () => {
    if (isHl7) {
      return DicomSectionComponentMapper[UPLOAD_STATUSES.completed];
    }

    if (isOrthanc && !isCancelled) {
      return DicomSectionComponentMapper[uploadStatus]
        ?? DicomSectionComponentMapper[UPLOAD_STATUSES.initiated];
    }

    return null;
  };

  const RenderedDicomSectionComponent = getDicomSectionComponent();

  const dicomContentSection = RenderedDicomSectionComponent
    ? <RenderedDicomSectionComponent jobRequest={currentJobRequest} isHl7={isHl7} />
    : null;

  return {
    isCancelled,
    dicom,
    dicomType,
    dicomContentSection,
  };
};

export default useJobRequest;
