import PropTypes from 'prop-types';
import './DataViewStyle.scss';

const DataView = (props) => {
  const {
    label,
    value,
    icon,
  } = props;
  return (
    <div className="data-view">
      {
        icon
          ? (
            <div className="text-with-icon">
              {icon}
              <p>
                <span className="as-label">
                  {label}
                </span>
                <span className="show-value">
                  {value}
                </span>
              </p>
            </div>
          )
          : (
            <>
              <p className="as-label">
                {label}
              </p>
              <p className="show-value">
                {value}
              </p>
            </>
          )
      }
    </div>
  );
};

DataView.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  icon: PropTypes.node,
};

DataView.defaultProps = {
  icon: '',
  value: '',
};

export default DataView;
