import graphql from 'babel-plugin-relay/macro';

const SaveJobRequestMutation = graphql`
  mutation SaveJobRequestMutation($input: SaveJobRequestInput!) {
    saveJobRequest(input:$input) {
      jobRequest {
        dbId
      }
      errors
    }
  }
`;

export default SaveJobRequestMutation;
