/* eslint-disable no-nested-ternary */
import Select from 'react-select';
import PropTypes from 'prop-types';
import './SelectStyle.scss';

const selectStyle = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    fontSize: '0.875rem',
    top: '1.5rem',
    right: '0rem',
    width: '100%',
  }),

  placeholder: (provided) => ({
    ...provided,
    color: '#9CB6C5',
  }),
  option: (styles, {
    isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isSelected
      ? 'transparent'
      : isFocused
        ? '#00b4aa'
        : ' ',
    color: isFocused && !isSelected
      ? 'white'
      : isSelected
        ? '#00b4aa;'
        : ' ',
    ':active': {
      ...styles[':active'],
      backgroundColor: !isSelected ? '#00b4aa;' : '',
    },
  }),
};

const SelectControl = ({
  options,
  handleChange,
  value,
  isDisabled,
  className,
  placeholder,
  selectId,
}) => (
  <div className={`app-select ${className}`}>
    <Select
      {...(selectId ? { inputId: selectId } : {})}
      styles={selectStyle}
      options={options}
      showSearch
      onChange={handleChange}
      aria-label="select-control"
      value={value}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  </div>
);

const option = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
});

SelectControl.propTypes = {
  options: PropTypes.oneOfType([PropTypes.arrayOf(option), PropTypes.func]).isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([option, PropTypes.string]).isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  selectId: PropTypes.string,
};
SelectControl.defaultProps = {
  isDisabled: false,
  className: '',
  placeholder: '',
  selectId: null,
};

export default SelectControl;
