/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobRequestFragment_jobRequest$ref = any;
export type DraftJobRequestQueryVariables = {|
  id: number
|};
export type DraftJobRequestQueryResponse = {|
  +jobRequest: ?{|
    +$fragmentRefs: JobRequestFragment_jobRequest$ref
  |},
  +abilities: ?{|
    +canDeleteJobRequest: boolean
  |},
  +serverErrors: ?$ReadOnlyArray<any>,
|};
export type DraftJobRequestQuery = {|
  variables: DraftJobRequestQueryVariables,
  response: DraftJobRequestQueryResponse,
|};
*/


/*
query DraftJobRequestQuery(
  $id: Int!
) {
  jobRequest(id: $id) {
    ...JobRequestFragment_jobRequest
    id
  }
  abilities {
    canDeleteJobRequest(id: $id)
  }
  serverErrors
}

fragment JobRequestFragment_jobRequest on JobRequest {
  dbId
  patientAge
  patientAgeUnit
  patientFullname
  patientGender
  examType {
    id
    dbId
    name
    modality {
      id
      dbId
      name
    }
  }
  urgency
  contrast
  status
  id
  patientClinicalData
  patientAdditionalInfo
  referringDoctor
  contract {
    id
    dbId
    user {
      id
      dbId
      name
    }
  }
  attachments
  dicom
  dicomType
  previousStudy
  modality
  uploadStatus
  dicomViewType
  studyDetails {
    studyDesc
    studyDate
    studyTime
  }
  radiologist {
    dbId
    id
  }
  report
  reportPdf
  draftReport
  createdAt
  remainingTat
  remainingTatInMins
  datasetIdAssignedToMedicalFacility
  isMedicalFacilityAssignedToBasicDataset
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Abilities",
  "kind": "LinkedField",
  "name": "abilities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "canDeleteJobRequest",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serverErrors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftJobRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobRequest",
        "kind": "LinkedField",
        "name": "jobRequest",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobRequestFragment_jobRequest"
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftJobRequestQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JobRequest",
        "kind": "LinkedField",
        "name": "jobRequest",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientAge",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientAgeUnit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientFullname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientGender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExamType",
            "kind": "LinkedField",
            "name": "examType",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Modality",
                "kind": "LinkedField",
                "name": "modality",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "urgency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contrast",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientClinicalData",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientAdditionalInfo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referringDoctor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attachments",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dicom",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dicomType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "previousStudy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modality",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uploadStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dicomViewType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StudyDetails",
            "kind": "LinkedField",
            "name": "studyDetails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDesc",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyTime",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "radiologist",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "report",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reportPdf",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "draftReport",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remainingTat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remainingTatInMins",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "datasetIdAssignedToMedicalFacility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMedicalFacilityAssignedToBasicDataset",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "b3e372cd8e28053a620a00e42e177fba",
    "id": null,
    "metadata": {},
    "name": "DraftJobRequestQuery",
    "operationKind": "query",
    "text": "query DraftJobRequestQuery(\n  $id: Int!\n) {\n  jobRequest(id: $id) {\n    ...JobRequestFragment_jobRequest\n    id\n  }\n  abilities {\n    canDeleteJobRequest(id: $id)\n  }\n  serverErrors\n}\n\nfragment JobRequestFragment_jobRequest on JobRequest {\n  dbId\n  patientAge\n  patientAgeUnit\n  patientFullname\n  patientGender\n  examType {\n    id\n    dbId\n    name\n    modality {\n      id\n      dbId\n      name\n    }\n  }\n  urgency\n  contrast\n  status\n  id\n  patientClinicalData\n  patientAdditionalInfo\n  referringDoctor\n  contract {\n    id\n    dbId\n    user {\n      id\n      dbId\n      name\n    }\n  }\n  attachments\n  dicom\n  dicomType\n  previousStudy\n  modality\n  uploadStatus\n  dicomViewType\n  studyDetails {\n    studyDesc\n    studyDate\n    studyTime\n  }\n  radiologist {\n    dbId\n    id\n  }\n  report\n  reportPdf\n  draftReport\n  createdAt\n  remainingTat\n  remainingTatInMins\n  datasetIdAssignedToMedicalFacility\n  isMedicalFacilityAssignedToBasicDataset\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '15b674d6f9558dfca23af008191237a9';

module.exports = node;
