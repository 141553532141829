import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './AppProgressBarStyle.scss';

const AppProgressBar = (props) => {
  const {
    percentage,
    className,
  } = props;
  return (
    <div className={`app-progressbar ${className}`}>
      <ProgressBar now={percentage} />
      <span className="percentage text-end d-block mt-2">{`${percentage}%`}</span>
    </div>
  );
};

AppProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  className: PropTypes.string,
};

AppProgressBar.defaultProps = {
  className: null,
};
export default AppProgressBar;
