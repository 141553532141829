import * as Yup from 'yup';
import { SUBMIT_TYPES } from 'src/utils/constants';
import validationMessages from '../../utils/validationMessages';

const allAttachmentsAttributes = {
  dicom: Yup.mixed()
    .test('chosen file has errors', validationMessages.fileHasErrors(), (file) => !file || !file.errors),
  attachments: Yup.array()
    .test('chosen files has errors', validationMessages.filesHasErrors(), (files) => !files?.length || !files.some((file) => file.errors)),
};

const ValidationSchema = Yup.object().shape({
  patientFullname:
    Yup.string().when('submitType', {
      is: (submitType) => submitType === SUBMIT_TYPES.COMPLETE,
      then: Yup.string().required(validationMessages.requiredField()),
      otherwise: Yup.string(),
    }),
  patientAge: Yup.number().when('submitType', {
    is: (submitType) => submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.number().min(1, validationMessages.minNumber(1))
      .required(validationMessages.requiredField()),
    otherwise: Yup.number().min(1, validationMessages.minNumber(1)),
  }),
  patientClinicalData: Yup.string().when('submitType', {
    is: (submitType) => submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.string().required(validationMessages.requiredField()),
    otherwise: Yup.string(),
  }),
  patientGender: Yup.string().when('submitType', {
    is: (submitType) => submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.string().required(validationMessages.requiredField()),
    otherwise: Yup.string(),
  }),
  urgency: Yup.string().when('submitType', {
    is: (submitType) => submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.string().required(validationMessages.requiredField()),
    otherwise: Yup.string(),
  }),
  modalityId: Yup.object().required(validationMessages.requiredField()),
  examTypeId: Yup.object().required(validationMessages.requiredField()),
  dicom: Yup.string().url(validationMessages.invalid('URL')).when(['dicomRadio', 'submitType'], {
    is: (dicomRadio, submitType) => dicomRadio === 'Url' && submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.string().required(validationMessages.requiredField()),
    otherwise: Yup.string(),
  }),
  attachmentsAttributes: Yup.object().when(['dicomRadio', 'submitType'], {
    is: (dicomRadio, submitType) => dicomRadio === 'File' && submitType === SUBMIT_TYPES.COMPLETE,
    then: Yup.object({
      ...allAttachmentsAttributes,
      dicom: allAttachmentsAttributes.dicom.required(validationMessages.requiredField()),
    }),
    otherwise: Yup.object(allAttachmentsAttributes),
  }),
});

export default ValidationSchema;
