/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type JobRequestFragment_jobRequest$ref = any;
export type CancelSyncDicomFileUploadInput = {|
  clientMutationId?: ?string,
  id: number,
|};
export type CancelSyncDicomFileUploadMutationVariables = {|
  input: CancelSyncDicomFileUploadInput
|};
export type CancelSyncDicomFileUploadMutationResponse = {|
  +cancelSyncDicomFileUpload: ?{|
    +jobRequest: ?{|
      +$fragmentRefs: JobRequestFragment_jobRequest$ref
    |},
    +errors: any,
  |}
|};
export type CancelSyncDicomFileUploadMutation = {|
  variables: CancelSyncDicomFileUploadMutationVariables,
  response: CancelSyncDicomFileUploadMutationResponse,
|};
*/


/*
mutation CancelSyncDicomFileUploadMutation(
  $input: CancelSyncDicomFileUploadInput!
) {
  cancelSyncDicomFileUpload(input: $input) {
    jobRequest {
      ...JobRequestFragment_jobRequest
      id
    }
    errors
  }
}

fragment JobRequestFragment_jobRequest on JobRequest {
  dbId
  patientAge
  patientAgeUnit
  patientFullname
  patientGender
  examType {
    id
    dbId
    name
    modality {
      id
      dbId
      name
    }
  }
  urgency
  contrast
  status
  id
  patientClinicalData
  patientAdditionalInfo
  referringDoctor
  contract {
    id
    dbId
    user {
      id
      dbId
      name
    }
  }
  attachments
  dicom
  dicomType
  previousStudy
  modality
  uploadStatus
  dicomViewType
  studyDetails {
    studyDesc
    studyDate
    studyTime
  }
  radiologist {
    dbId
    id
  }
  report
  reportPdf
  draftReport
  createdAt
  remainingTat
  remainingTatInMins
  datasetIdAssignedToMedicalFacility
  isMedicalFacilityAssignedToBasicDataset
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelSyncDicomFileUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSyncDicomFileUploadPayload",
        "kind": "LinkedField",
        "name": "cancelSyncDicomFileUpload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobRequest",
            "kind": "LinkedField",
            "name": "jobRequest",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "JobRequestFragment_jobRequest"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelSyncDicomFileUploadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSyncDicomFileUploadPayload",
        "kind": "LinkedField",
        "name": "cancelSyncDicomFileUpload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "JobRequest",
            "kind": "LinkedField",
            "name": "jobRequest",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientAge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientAgeUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientFullname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientGender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ExamType",
                "kind": "LinkedField",
                "name": "examType",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Modality",
                    "kind": "LinkedField",
                    "name": "modality",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "urgency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contrast",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientClinicalData",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "patientAdditionalInfo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referringDoctor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "contract",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attachments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dicom",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dicomType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previousStudy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modality",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uploadStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dicomViewType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "StudyDetails",
                "kind": "LinkedField",
                "name": "studyDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyDesc",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "studyTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "radiologist",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "report",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reportPdf",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draftReport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remainingTat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remainingTatInMins",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "datasetIdAssignedToMedicalFacility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isMedicalFacilityAssignedToBasicDataset",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1688ce84e96df75eb7af752a59cb54a",
    "id": null,
    "metadata": {},
    "name": "CancelSyncDicomFileUploadMutation",
    "operationKind": "mutation",
    "text": "mutation CancelSyncDicomFileUploadMutation(\n  $input: CancelSyncDicomFileUploadInput!\n) {\n  cancelSyncDicomFileUpload(input: $input) {\n    jobRequest {\n      ...JobRequestFragment_jobRequest\n      id\n    }\n    errors\n  }\n}\n\nfragment JobRequestFragment_jobRequest on JobRequest {\n  dbId\n  patientAge\n  patientAgeUnit\n  patientFullname\n  patientGender\n  examType {\n    id\n    dbId\n    name\n    modality {\n      id\n      dbId\n      name\n    }\n  }\n  urgency\n  contrast\n  status\n  id\n  patientClinicalData\n  patientAdditionalInfo\n  referringDoctor\n  contract {\n    id\n    dbId\n    user {\n      id\n      dbId\n      name\n    }\n  }\n  attachments\n  dicom\n  dicomType\n  previousStudy\n  modality\n  uploadStatus\n  dicomViewType\n  studyDetails {\n    studyDesc\n    studyDate\n    studyTime\n  }\n  radiologist {\n    dbId\n    id\n  }\n  report\n  reportPdf\n  draftReport\n  createdAt\n  remainingTat\n  remainingTatInMins\n  datasetIdAssignedToMedicalFacility\n  isMedicalFacilityAssignedToBasicDataset\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f61087a1891ff471771212568230d9f';

module.exports = node;
